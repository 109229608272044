

.header{
  display: flex;
  height : 50px ;
  width : 1600px ;
  background-color: #21bfae;

} 

.mobnumber{
margin-top : 8px ;
margin-left: 130px;
color: aliceblue;
}
.info{
margin-left: 50px;
color: aliceblue;
margin-top:8px;
}

.fb{
margin-left: 340px;
margin-top: 10px;
}
.twit{
margin-left: 50px;
margin-top: 10px;
}
.mid{
text-align: center;
display: grid ;
}



.bg{

width : 1600px ;
height : 850px ;
background-image:url("./images/istockphoto-1214716068-1024x1024 1.png") ; 

}

.log{
margin-top: 140px;
margin-left:650px ;
width : 280px ;
height : 270px ;
background-image: url("./images/Logo 1 (1).png");
background-repeat: no-repeat;
}
.bgvideo{
  width : 1600px ;
  height : 850px ;
  z-index: -1;
  position:absolute;
}
.post{
 margin-top: -350px;
float: right;
margin-right: 180px;
width : 280px ;
height : 270px 
}
.mid-mid{
  margin : 60px
}
.blogs{
  margin-left: 90px;

}
.blogs >div{
  display: inline-block ;
  margin-left : 250px ;
 
 
}
.bloghead{
   border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
   width : 1250px ;
   height : 100px
  
}
.heading{
  padding-top: 50px;
  margin-left: 370px;
  color:#21bfae;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}



.searchbar{
  display : flex ;
  width : 1150px ;
  height : 50px ;
 margin-left: 130px;
 margin-top: 20px;
 background-color: white;
 border-radius: 20px;
 border-color: black;
padding: 50px ;
}

.searchbar >div{
  margin-left: 20px;
}

.searchbutton{
  margin-top: 10px;
}

.search{
  display : flex ;
  width : 1250px ;
  height : 100px ;
 margin-left: 130px;
 margin-top: 30px;
 background-color:white ;
 border-radius: 20px;
 border-color: #21bfae;
 border-width: 2px;
 padding: 10px ;


} 
.search >div{
  margin : 10px
}

.head{
  display : flex ;
  max-width: fit-content;
  height : 120px ;


}
.headlogo{

width : 200px;
height : 150px ;
background-image: url("./images/Logo 200.1.jpg");
background-repeat: no-repeat;

}
.head>div{
  margin : 15px;
}

.dropbtn {
  background-color: #21bfae;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  ;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color:#21bfae;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #21bfae;
  color : white
}


.propertyform{
  background-color:white;
 
  margin-left: 600px;

  padding : 30px ;
 
  background-repeat: no-repeat;
  height : 80px ;
  width :300px

}
.probutton{
   
  border-radius: 4px;
  background-color: #21bfae;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 350px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
} 
.probutton span{
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.probutton span:after{
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
} 

.probutton:hover span {
  padding-right: 25px;
}
.probutton:hover span:after {
  opacity: 1;
  right: 0;
}
.twitlogout{
  margin-left: 50px;
margin-top: 10px;
}
.allpro{

  display: grid;
  grid-template-columns: auto auto auto ;
 margin-top: 50px;
margin-left: 200px;
}

.allpro>div{
  margin-bottom: 200px;
}

.midprobtn{
  border-radius: 4px;
  background-color: #21bfae;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 350px;
  transition: all 0.5s;
  cursor: pointer;
  margin-right: 100px;
}

.midprobtn span{
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.midprobtn span :after{
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.midprobtn:hover span {
  padding-right: 25px;
}

.midprobtn:hover span:after{
  opacity: 1;
  right: 0;
}
.headertwo{
  display : none ;
  width : 430px ;
  height : 50px ;
  background-color: #21bfae;
}

@media only screen and (max-width : 630px){
  .header{
   display : none;
    width : 630px ;
    
} ;


.mid{
display : grid ;
width :630px ;
}

.search{
 
  display : grid ;
  width : 430px ;
  height : 330px ;
 margin-left: 00px;
 margin-top: 10px;
 background-color:white ;
 border-radius: 20px;

}

.headertwo{
  display : flex ;
  width : 630px ;
  height : 50px ;
  background-color: #21bfae;
}
.mobnumber{
  margin-left: 30px;
}
.bg{
  display : grid ;
 width : 430px ;
height : 750px ;
background-image:url("./images/istockphoto-1214716068-1024x1024 630.png") ; 
background-repeat: no-repeat ;
}

.log{
margin-top: 220px;
margin-left: 110px ;
width : 180px ;
height : 150px ;
background-image: url("./images/Logo 200.1.jpg");
background-repeat: no-repeat;
}

.post{
  margin-top: -260px;
  height : 0px ;
  margin-left: 40px;
}
                           
.midbtn{

  margin-left:80px  ;
  
 
}
.midprobtn{

  margin-top: -50px;

  width : 200px ;
  height : 50px ;
  font-size: large;
  padding : 10px
}
.bloghead{
  width :430px ;
  margin-left: 10px;
}
.heading{
  margin-left: 00px;
}
  
}

.signin{
 
  height: 300px;
  display : flex ;
  background-image: url("./images/Logo 1 (1).png") ;
  background-repeat: no-repeat;
 
}

.btnsign{
  padding-top: 5px;
  margin-left: 20px;
  border-radius: 10px;
  color:white;
  background-color: white;
  height : 40px
}
.btnsignup{
  padding-top: 5px;
  margin-left: 20px;
  border-radius: 10px;
  color: white;
  background-color: white;
  height : 40px
}

.spa{

  color: white ;
  margin-left: 20px;
  margin-top:  10px ;
}

.twituser{
  margin-left: 50px;
margin-top: 10px;
color:white
}

.twitlogout{
  margin-left: 50px;
  margin-top: 10px;
  color:white ;
  background-color: antiquewhite;
  margin-bottom: 5px;
  border-radius: 10px;
}

.midbtn{
  
  margin-top: -350px;
  float: right;
  margin-right: 1150px;
  width : 280px ;
  height : 270px 
 
}
.midlast{
  width : auto ;
  height : 30px ;
  background-color: grey
}




.title{
  padding-top: 20px;
  margin-left: 205px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  color:#21bfae
}
.midheading{
  margin-left: 650px; 
 color: white ;
 font-family :'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.websitefooter{
  display : flex ;
  background-color: black;
  width : auto ;
  height : 500px ;
 
  background-repeat: no-repeat;
}

.footerimg{
  margin-top: 150px;
  margin-left: 50px;
}
.cont{
  color : white ;
  margin-left: 100px;
 font-family:Arial, Helvetica, sans-serif;
 font-size: larger;
 margin-top: 20px;
}
.footql{
  margin-left: 100px;
  color : white ;
}
.hmlink{
 
  color: white;
  text-decoration: none;
  font-family:sans-serif;
  font-size: larger;
  margin: 20px;

  
}
.footabt{
  margin-left: 70px;
   color : white
}
.footabtp{
  margin-left : 70px ;
  color : white
}
.read{
  color : goldenrod
}

